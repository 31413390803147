import $ from 'jquery';
import clamp from 'clamp-js-main/clamp.js';

$(document).ready(function() {
  if($('#side .articleList .txtIntro a').length) {
    $('#side .articleList .txtIntro a').each(function(){
      var $el = $(this);
      clamp($el.get(0), {clamp: 3});
    });
  }
});
