import $ from 'jquery';
import app from './app';
// ---------------------------------------------------------
// Custom Functions for Front-End
// ---------------------------------------------------------
$(document).ready(function() {
  $('.tab a').on('click', function() {
    var elm = $(this);
    if (!elm.parent('li').hasClass('active')) {
      elm.parent('li')
        .siblings()
        .removeClass('active');
      elm.parent('li').addClass('active');
      elm.parents('.headTab')
        .next()
        .children('.tabBox')
        .hide();
      $(this.hash).fadeIn();
    }
    return false;
  });
  $('.tab02 a').on('click', function(e) {
    var elm = $(this);
    if (!elm.parent('li').hasClass('active')) {
      elm.parent('li')
        .siblings()
        .removeClass('active');
      elm.parent('li').addClass('active');
      elm.parents('.headTab02')
        .next()
        .find('.tabBlock')
        .hide();
      $(this.hash).fadeIn();
    }
    return false;
  });

  // menu
  $('.navToggle').on('click', function() {
    var $elm = $(this);
    if ($elm.hasClass('active')) {
      $('.navHeader').fadeOut();
      $elm.removeClass('active');
    } else {
      $('.navHeader').fadeIn().css({'display':'flex'});
      $elm.addClass('active');
    }
  });
  $('body').on('click', '.mega-menu-link', function(e){
    var $elm = $(this);
    if(($(window).width() < 1000) && ($elm.attr('aria-haspopup')=="true")) {
      e.preventDefault();
      if ($elm.hasClass('active')) {
        $elm.next('.mega-sub-menu').stop().slideUp();
        $elm.removeClass('active');
      } else {
        $elm.parent().siblings('.mega-menu-item').children('.mega-sub-menu').stop().slideUp();
        $elm.parent().siblings('.mega-menu-item').children('.mega-menu-link').stop().removeClass('active');
        $elm.next('.mega-sub-menu').stop().slideDown();
        $elm.addClass('active');
      }
    }
  })

  // footer
  $('.jsToggle a, .jsToggle span').on('click', function(){
    var $elm = $(this).parent();
    var accordionWrap = $elm.next('.subNav');
    if(accordionWrap.length){

       e.preventDefault();
      if($elm.hasClass('active')){
        accordionWrap.slideUp();
        $elm.removeClass('active')
      }else {
        accordionWrap.slideDown();
        $elm.addClass('active')
      }
    }
  })
  if($(window).width() <= 768){
    var col05_html = $('#menu-footer-column5 li').eq(0).html();
    console.log(col05_html);
    $('.col05 .footer_title').html(col05_html);
  }
  $('.footer_title').on('click', function(e){
    var $elm = $(this);
    var accordionWrap = $elm.next().children('.menu');
    if(accordionWrap.length && ($(window).width() <= 768) && !$elm.parent().hasClass('col05')){
      e.preventDefault();
      if($elm.hasClass('active')){
        accordionWrap.stop().slideUp();
        $elm.removeClass('active')
      }else {
        accordionWrap.stop().slideDown();
        $elm.addClass('active')
      }
    }
  })

  // header
  $(window).on('load scroll', function () {
		var scrollTop = $(window).scrollTop();
		if(scrollTop > 65) {
			$('#header').addClass('active');
		}else {
			$('#header').removeClass('active');
		}
  });

  // Object-fit IE
  if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
    $('.jsBgIE').each(function() {
      var $this = $(this),
        url = 'url(' + $this.find('img').attr('src') + ')';
      $this.css({
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': '50% 50%',
        'background-image': url
      });
      $this.children('img').hide();
    });
  }


  //
  function getParameterByName(name, url) {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  $(window).on('load', function (e) {
    var myhash = '#' + getParameterByName('id', window.location);
    if (myhash != '#' && $(myhash).length > 0) {
      var offTop = $(myhash).offset().top - $('#header').outerHeight();
      $('body, html').animate({
        scrollTop: offTop
      }, 500);
    }
    e.preventDefault();
  });

  $('#nav > li > a').on('click', function(e){
    var $elm = $(this);
    var accordionWrap = $elm.next('.subNav');
    if(accordionWrap.length){
      e.preventDefault();
      if($elm.hasClass('active')){
        accordionWrap.slideUp();
        $elm.removeClass('active')
      }else {
        accordionWrap.slideDown();
        $elm.addClass('active')
      }
    }
  })
  var myhash = location.hash;
  anchorToTab(myhash);
  $('.jsAnchorTab a').on('click', function(e){
    var href = $(this).attr("href");
    var hash = href.substr(href.indexOf("#"));
    anchorToTab(hash);
  });
  $(".jsAccordion .faqQuestion").click(function() {
    var elm = $(this);
    if (elm.next(".faqAnswer").is(":visible")) {
        elm.removeClass("active")
            .next(".faqAnswer")
            .slideUp(200);
    } else {
        elm.next(".faqAnswer")
            .slideDown(200)
            .siblings(".faqAnswer")
            .slideUp(200)
            .siblings(".faqQuestion")
            .removeClass("active");
        elm.addClass("active");
    }
  });

  // anchorlink
  $('.jsAnchor a[href^=\\#]').click(function(e) {
    var anchorLink = $(this).attr('href');
    if (anchorLink != '#') {
        var offTop = $(anchorLink).offset().top - $('#header').height();
        $('body, html').stop().animate({scrollTop:offTop}, 500);
    }
    return false;
  });


});
function anchorToTab(myhash){
  if (myhash.length) {
    if(!$(".sectionNews02 a[href='#tabBlock01']").parent().hasClass('active')){
      $(".sectionNews02 a[href='#tabBlock01']").trigger('click');
    }
    $(".sectionNews02 a[href='"+myhash+"']").trigger("click");
  }
  if(myhash.length){
    var offTop = $(".sectionNews02").offset().top - $('#header').height();
    $('body, html').stop().animate({scrollTop:offTop}, 500);
  }
}
