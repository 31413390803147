import $ from 'jquery';
import '../vendors/biggerlink';

$(document).ready(function() {
  $('.listNews li').biggerlink({
    otherstriggermaster: false
  });
  $('.articleList li').biggerlink();
  $('.biggerlink a[target="_blank"]').click(function(){
    window.open(this.href);
    return false;
  });
  $('.biggerlink').biggerlink({
    otherstriggermaster: false
  });
});
